import { StyledFooter, FooterSocialLinks, FooterLogo } from './styles/common.styled';
import moment from 'moment';

export const Footer = () => {
    return (
        <StyledFooter>
            <div>
                <FooterLogo><svg version="1.1" viewBox="0 0 964.77332 421.43066" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(1.3333 0 0 -1.3333 0 421.43)"><g transform="translate(124.22 274.46)"><path d="m0 0h14.658v-78.719c6.158 9.237 16.418 14.953 28.439 14.953 26.24 0 41.924-16.857 41.924-46.175v-93.378c0-11.873 6.89-18.03 17.005-18.03 8.795 0 15.979 4.836 21.989 20.522l9.234 24.48-42.657 104.96 13.486 6.01 36.207-92.644 26.974 71.39c5.424 14.512 13.486 20.668 27.558 20.668h8.943v65.965h14.659v-65.965h20.081v-13.926h-20.081v-58.05c0-13.78 7.183-21.988 19.056-21.988 12.313 0 19.349 7.915 19.349 21.988v137.94h14.66v-78.864c6.156 9.234 16.418 14.951 28.439 14.951 26.386 0 41.924-16.858 41.924-46.028v-33.863c0-9.821 4.691-16.125 12.754-16.125 8.061 0 12.166 5.57 12.166 16.125v77.693h14.659v-12.753c6.156 9.234 16.418 14.951 28.438 14.951 18.177 0 30.491-8.063 35.182-24.481 4.544 14.22 18.031 24.481 35.182 24.481 26.386 0 41.924-16.858 41.924-46.028v-29.465c0-14.219 8.501-22.429 21.548-21.11v-13.046c-22.281-1.465-36.208 11.727-36.208 34.01v29.611c0 19.643-9.675 32.103-27.264 32.103-17.738 0-28.439-12.607-28.439-32.103v-58.783h-14.659v58.783c0 19.643-9.676 32.103-27.266 32.103-17.737 0-28.438-12.607-28.438-32.103v-33.863c0-19.056-10.114-30.051-26.825-30.051-17.152 0-27.414 11.434-27.414 30.051v33.863c0 19.643-9.674 32.103-27.264 32.103-17.737 0-28.439-12.607-28.439-32.103v-28c0-23.013-12.167-35.767-34.009-35.767-20.816 0-33.715 13.632-33.715 35.767v58.05h-7.623c-7.036 0-12.314-4.545-15.392-12.9l-43.683-114.34c-7.916-20.669-19.644-28.144-34.45-28.144-19.789 0-32.249 11.139-32.249 31.956v93.378c0 19.789-9.822 32.249-27.266 32.249-17.738 0-28.439-12.606-28.439-32.249v-58.636l-14.658 0.145zm-71.536-65.965h14.659v-22.136c7.77 16.712 21.549 24.775 40.753 24.042v-14.366c-24.189 0.733-40.753-19.935-40.753-50.72v-39.432h-14.659z"></path></g></g></svg></FooterLogo>
                <div style={{margin:'20px 0'}}>© {moment().format('YYYY')} Rhythm Ops, LLC, d/b/a Rhythm, 24 Greenway Plaza Suite 610, Houston, TX 77046. All Rights Reserved. PUCT #10279</div>
            </div>
            <FooterSocialLinks>
				<a href="https://www.facebook.com/gotrhythmHQ/" target="_blank" rel="noopener noreferrer"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg></a>
				<a href="https://twitter.com/GotRhythmHQ" target="_blank" rel="noopener noreferrer"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg></a>
				<a href="https://www.linkedin.com/company/rhythmhq" target="_blank" rel="noopener noreferrer"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg></a>
				<a href="https://www.instagram.com/gotrhythmhq/" target="_blank" rel="noopener noreferrer"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg></a>
				<a href="https://www.youtube.com/channel/UCWMtIY_FHxEn9SXMYZAiMMw" target="_blank" rel="noopener noreferrer"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
			</FooterSocialLinks>
        </StyledFooter>
    )
}