import { useState } from "react";
import {
    StyledHeader, Logo, StyledHeaderInner,
    HeaderMenu, HeaderMenuItem, MenuToggle
} from "./styles/common.styled";
import { Button } from '@mui/material';

export const Header = () => {
    const [ showMenu, setShowMenu ] = useState(false);

    return (
        <StyledHeader>
            <StyledHeaderInner>
                <Logo href="/">
                    <svg version="1.1" viewBox="0 0 964.77332 421.43066" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(1.3333 0 0 -1.3333 0 421.43)"><g transform="translate(124.22 274.46)"><path d="m0 0h14.658v-78.719c6.158 9.237 16.418 14.953 28.439 14.953 26.24 0 41.924-16.857 41.924-46.175v-93.378c0-11.873 6.89-18.03 17.005-18.03 8.795 0 15.979 4.836 21.989 20.522l9.234 24.48-42.657 104.96 13.486 6.01 36.207-92.644 26.974 71.39c5.424 14.512 13.486 20.668 27.558 20.668h8.943v65.965h14.659v-65.965h20.081v-13.926h-20.081v-58.05c0-13.78 7.183-21.988 19.056-21.988 12.313 0 19.349 7.915 19.349 21.988v137.94h14.66v-78.864c6.156 9.234 16.418 14.951 28.439 14.951 26.386 0 41.924-16.858 41.924-46.028v-33.863c0-9.821 4.691-16.125 12.754-16.125 8.061 0 12.166 5.57 12.166 16.125v77.693h14.659v-12.753c6.156 9.234 16.418 14.951 28.438 14.951 18.177 0 30.491-8.063 35.182-24.481 4.544 14.22 18.031 24.481 35.182 24.481 26.386 0 41.924-16.858 41.924-46.028v-29.465c0-14.219 8.501-22.429 21.548-21.11v-13.046c-22.281-1.465-36.208 11.727-36.208 34.01v29.611c0 19.643-9.675 32.103-27.264 32.103-17.738 0-28.439-12.607-28.439-32.103v-58.783h-14.659v58.783c0 19.643-9.676 32.103-27.266 32.103-17.737 0-28.438-12.607-28.438-32.103v-33.863c0-19.056-10.114-30.051-26.825-30.051-17.152 0-27.414 11.434-27.414 30.051v33.863c0 19.643-9.674 32.103-27.264 32.103-17.737 0-28.439-12.607-28.439-32.103v-28c0-23.013-12.167-35.767-34.009-35.767-20.816 0-33.715 13.632-33.715 35.767v58.05h-7.623c-7.036 0-12.314-4.545-15.392-12.9l-43.683-114.34c-7.916-20.669-19.644-28.144-34.45-28.144-19.789 0-32.249 11.139-32.249 31.956v93.378c0 19.789-9.822 32.249-27.266 32.249-17.738 0-28.439-12.606-28.439-32.249v-58.636l-14.658 0.145zm-71.536-65.965h14.659v-22.136c7.77 16.712 21.549 24.775 40.753 24.042v-14.366c-24.189 0.733-40.753-19.935-40.753-50.72v-39.432h-14.659z"></path></g></g></svg>
                    <span>Side-by-side</span>
                </Logo>
                <MenuToggle
                    active={`${showMenu}`}
                    onClick={() => {
                        if (showMenu) {
                            document.body.style.overflow = 'unset'
                        }
                        else {
                            document.body.style.overflow = 'hidden'
                        }
                        setShowMenu(!showMenu)
                    }}
                />
                <HeaderMenu active={`${showMenu}`}>
                    <HeaderMenuItem href="https://www.gotrhythm.com/about">About Us</HeaderMenuItem>
                    <Button size="medium" variant="outlined" onClick={() => {
                        window.location.href = "https://app.gotrhythm.com/sign-up?locale=en&featureFlagUserId=rh_23981acc-ce94-4a28-a133-1e5779ed5a7d&rcid=default"
                    }}>Sign up</Button>
                </HeaderMenu>
            </StyledHeaderInner>
        </StyledHeader>
    )
}