import { useEffect, useState, useRef, useCallback } from "react";
import {
    Button, Container, TextField, Grid, MenuItem, Autocomplete
} from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { ComparePageHead } from "./components/styles/compare.styled";
import { EntrySection, SavingRow } from "./components/styles/common.styled";
import { CompareChart } from "./components/CompareChart";
import { CompareAside } from "./components/CompareAside";
import {
    fetchAddress, fetchUtility, fetchTerms, fetchPlan,
    fetchEstUsage, fetchPremiseDetail
} from "./fetch/fetch";
import { PlanTypes, ChartDataTypes, PremiseDetailProps, AddressProps, UtilityTypes } from "./types";
import { REPs } from "./config";
import NumberFormat from 'react-number-format';
import { PlanModal } from './components/PlanModal';
import { RollingNumber } from './components/RollingNumber';
import { GetUsageSidebar } from "./components/GetUsageSidebar";
import { MyAddressContext } from './context-manager';
import throttle from 'lodash.throttle';
import { withSnackbar } from './components/SnackbarHOC';

const Compare = ({
    snackbarShowMessage
}: {
    snackbarShowMessage?: Function;
}) => {
    const [ address, setAddress ] = useState('');
    const [ selectedTerm, setSelectedTerm ] = useState('');
    const [ addressOptions, setAddressOptions ] = useState([]);
    const [ addressLoading, setAddressLoading ] = useState(false);
    const [ selectedAddress, setSelectedAddress ] = useState<AddressProps|undefined>(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ utilities, setUtilities ] = useState<UtilityTypes[]>([]);
    const [ selectedUtility, setSelectedUtility ] = useState('');
    const [ termOptions, setTermOptions ] = useState([]);
    const [ chartData, setChartData ] = useState<ChartDataTypes[]>([]);
    const [ usages, setUsages ] = useState<number[]>([]);
    const [ isValidAddress, setIsValidAddress ] = useState(false);
    const [ premiseDetail, setPremiseDetail ] = useState<PremiseDetailProps|undefined>(undefined);
    const [ premiseLoading, setPremiseLoading ] = useState(false);
    const [ showModal, setShowModal ] = useState(false);
    const [ oneRepPlanList, setOneRepPlanList ] = useState<PlanTypes[]>([]);
    const [ plans, setPlans ] = useState<{[key: string]: PlanTypes[]}|undefined>(undefined);
    const [ consentId, setConsentId ] = useState('');
    const [ sourceFrom, setSourceFrom ] = useState('');
    const [ showGetUsageSidebar, setShowGetUsageSidebar ] = useState(false);
    const [ isEstMode, setIsEstMode ] = useState(true);
    const mainRef = useRef<null | HTMLDivElement>(null);

    const fetchAddressDelayed = useCallback(
        throttle((text, callback) => {
            if (text && text.trim()) {
                setAddressLoading(true);
                fetchAddress(text.trim()).then(callback);
            }
        }, 1000),
        []
    )

    const handleChangeAddress = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setAddress(value);
        setIsValidAddress(false);
        setUtilities([]);
        setSelectedUtility('');
        setSelectedTerm('');
        setChartData([]);
    }

    useEffect(() => {
        fetchAddressDelayed(address, (result: any) => {
            setAddressLoading(false);
            setAddressOptions(result || []);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])

    const handleGetData = () => {
        if (!selectedAddress) return;

        const { zipcode, street } = selectedAddress;
        setLoading(true);
        getEstUsage();
        setPremiseLoading(true);

        const utilityCode = findUtilityCode();

        if (utilityCode) {
            fetchTerms(utilityCode).then(res => {
                if (res && res.code === 1) {
                    let terms = res.response;
                    terms.sort((a: string, b: string) => {
                        if (parseInt(a) < parseInt(b)) return -1;
                        if (parseInt(a) > parseInt(b)) return 1;
                        return 0;
                    });
                    setTermOptions(terms);
                    if (terms.length > 0 && selectedTerm === '') {
                        if (terms.indexOf('12') >= 0) {
                            setSelectedTerm('12');
                        }
                        else {
                            setSelectedTerm(terms[0]);
                        }
                    }
                }
                else {
                    snackbarShowMessage?.(res.msg, 'error');
                    setLoading(false);
                }
            })
        }
        
        fetchPremiseDetail(street, zipcode).then(res => {
            setPremiseLoading(false);
            setPremiseDetail((res && res.premise && res.premise.detail) ? res.premise.detail : undefined);
        })
    }

    const findUtilityCode = () => {
        if (!selectedUtility) return '';

        return utilities.filter((val) => val.utility_name === selectedUtility)[0].utility_code;
    }

    const getUtility = () => {
        if (!selectedAddress) return;

        const { zipcode } = selectedAddress;

        fetchUtility(zipcode).then(res => {
            if (res && res[0] && res[0].utility_code) {
                setUtilities(res);

                if (res[0] && res[0].utility_code && !selectedUtility) {
                    setSelectedUtility(res[0].utility_name);
                }
            }
            else {
                snackbarShowMessage?.(res.message, 'error');
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getUtility();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress])

    const getEstUsage = () => {
        if (!selectedAddress) return;

        const { zipcode, city, state, street } = selectedAddress;
        fetchEstUsage(street, city, state, zipcode).then(res => {
            if (res && res.status === 1) {
                const { usages } = res;
                const usageArr = Object.keys(usages).map(key => usages[key]);
                setUsages(usageArr);
            }
        })
    }

    const handleScroll = () => {
        if (mainRef && mainRef.current) {
            mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    useEffect(() => {
        if (selectedTerm && usages.length > 0) {
            getPlan();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTerm, usages])

    const getPlan = () => {
        setLoading(true);

        const utilityCode = findUtilityCode();

        fetchPlan(
            parseInt(selectedTerm),
            utilityCode,
            usages
        ).then(res => {
            setLoading(false);

            let data = [];

            if (res && res.code === 1) {
                const allPlans = res.response;
                for (let i in allPlans) {
                    const plans = allPlans[i];
                    let totalRate = 0;
                    plans.forEach((val: PlanTypes, idx: number) => {
                        const { total_rate } = val;
                        totalRate += total_rate;
                    });
                    
                    data.push({
                        id: i,
                        value: Math.ceil(totalRate / plans.length * 100) / 100,
                        label: REPs.filter(val => val.providerId === i)[0].providerName
                    });
                }
                setPlans(allPlans);
                handleSetChartData(data);

                setTimeout(() => {
                    handleScroll();
                }, 200);
            }
            else {
                snackbarShowMessage?.(res.msg, 'error');
            }
        })
    }

    const handleSetChartData = (data: ChartDataTypes[]) => {
        let arr = data;

        arr.sort((a, b) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
        });

        let _chartData = [];
        const hostRep = REPs.filter(val => val.host === true);
        const hostData = arr.filter(val => val.id === hostRep[0].providerId);
        
        if (hostData[0]) {
            _chartData.push(hostData[0]);
        }
        
        const hostDataIdx = arr.findIndex(val => val.id === hostData[0].id);
        arr.splice(hostDataIdx, 1);
        _chartData = _chartData.concat(arr.slice(-3));
        
        setChartData(_chartData);
    }

    let energySavings = 0;
    if (usages && chartData && chartData.length > 0) {
        const rateDiff = chartData[chartData.length - 1].value - chartData[0].value;
        const totalUsage = usages.reduce((partialSum, a) => partialSum + a, 0);
        energySavings = rateDiff * (totalUsage / 12) * parseInt(selectedTerm) / 100;
    }
    
    return (
        <>
            <EntrySection style={{padding:'44px 0'}}>
                <Container>
                    <ComparePageHead>
                        <h2>For a Personalized Savings Calculation</h2>
                        <p style={{color:'#999',fontSize:14}}>(Enter your home address)</p>
                    </ComparePageHead>
                    <Grid container spacing={3} sx={{mt:2}} justifyContent="center">
                        <Grid item xs={12} sm={8}>
                            <Autocomplete
                                freeSolo
                                onChange={(evt, val) => {
                                    setAddress(val ? val.label : '');
                                    setSelectedAddress(val);
                                    setIsValidAddress(true);
                                }}
                                options={addressOptions.map((option: any) => option)}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>{option.label}</li>
                                )}
                                loading={addressLoading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Please Enter Your Address"
                                        value={address}
                                        onChange={handleChangeAddress}
                                        disabled={loading}
                                    />
                                )}
                            />
                        </Grid>
                        {
                            (utilities && utilities.length > 1) &&
                            <Grid item xs={12} sm={4}>
                                <Select
                                    labelId="utility-label"
                                    id="utility"
                                    value={selectedUtility}
                                    onChange={(evt: SelectChangeEvent) => {
                                        setSelectedUtility(evt.target.value as string);
                                    }}
                                    disabled={loading}
                                    fullWidth
                                    sx={{textAlign:'left'}}
                                >
                                    {
                                        utilities.map((val: any, idx) => <MenuItem value={val.utility_name} key={idx}>{val.utility_name}</MenuItem>)
                                    }
                                </Select>
                            </Grid>
                        }
                    </Grid>
                    <div style={{textAlign:'center',marginTop:24}}>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={handleGetData}
                            disabled={loading || !isValidAddress || !selectedUtility}
                        >Continue</Button>
                    </div>
                </Container>
            </EntrySection>
            <Container>
                {
                    selectedTerm &&
                    <div ref={mainRef}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={4}>
                                <CompareAside
                                    premiseLoading={premiseLoading}
                                    address={selectedAddress ? selectedAddress.label : ''}
                                    premiseDetail={premiseDetail}
                                    usages={usages}
                                    handleShowSidebar={() => setShowGetUsageSidebar(true)}
                                    handleGetEstUsage={() => {
                                        getEstUsage();
                                        setIsEstMode(true);
                                    }}
                                    handleChangeUsage={(usages: number[]) => {
                                        setUsages(usages);
                                        setIsEstMode(true);
                                    }}
                                    isEstMode={isEstMode}
                                    sourceFrom={sourceFrom}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <div style={{textAlign:'center',marginBottom:24}}>
                                    <h2 style={{marginTop:0}}>Estimated Total Savings = <RollingNumber data={energySavings} styles={{color:'#9633FF',fontWeight:700}} /></h2>
                                    {
                                        !isEstMode &&
                                        <p>(Based on personalized historic usage)</p>
                                    }
                                </div>
                                <Grid container>
                                    <Grid container item xs={6} alignItems="center">
                                        {/* <SavingRow>Energy Savings: <NumberFormat value={energySavings} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></SavingRow> */}
                                    </Grid>
                                    <Grid container item xs={6} alignItems="center" justifyContent="flex-end">
                                        <span style={{marginRight:12}}>Term:</span>
                                        <Select
                                            labelId="term-select-label"
                                            id="term-select"
                                            value={selectedTerm}
                                            onChange={(evt: SelectChangeEvent) => {
                                                setSelectedTerm(evt.target.value as string);
                                            }}
                                            disabled={loading}
                                        >
                                            {
                                                termOptions.map((val, idx) => <MenuItem value={val} key={idx}>{`${val} Months`}</MenuItem>)
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>
                                <CompareChart
                                    chartData={chartData}
                                    handleShowPlan={(id: string) => {
                                        setShowModal(true);
                                        if (plans && plans[id]) {
                                            setOneRepPlanList(plans[id]);
                                        }
                                    }}
                                />
                                <div style={{textAlign:'center',marginTop:40,marginBottom:64}}>
                                    <Button size="large" variant="contained" onClick={() => {
                                        window.location.href = "https://app.gotrhythm.com/sign-up?locale=en&featureFlagUserId=rh_23981acc-ce94-4a28-a133-1e5779ed5a7d&rcid=default"
                                    }}>Sign Up</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                }
            </Container>
            <PlanModal
                show={showModal}
                data={oneRepPlanList}
                handleCloseModal={() => { setShowModal(false); }}
            />
            <MyAddressContext.Provider value={{
                myAddress: selectedAddress,
                utilityCode: findUtilityCode(),
                sourceFrom: sourceFrom,
                handleSetUsage: (val: {usage: number[], source: string}) => {
                    setUsages(val.usage);
                    setSourceFrom(val.source);
                    if (val.source) {
                        setIsEstMode(false);
                    }
                    setShowGetUsageSidebar(false);
                }
            }}>
                <GetUsageSidebar
                    show={showGetUsageSidebar}
                    handleClose={() => {
                        setShowGetUsageSidebar(false);
                    }}
                    handleSetConsentId={(val: string) => setConsentId(val)}
                    zipcode={selectedAddress ? selectedAddress.zipcode : ''}
                />
            </MyAddressContext.Provider>
        </>
    )
}

export default withSnackbar(Compare)