export const REPs = [
    {
        providerId: '239',
        providerName: 'Rhythm',
        host: true
    },
    {
        providerId: '163',
        providerName: 'Direct Energy'
    },
    {
        providerId: '176',
        providerName: 'TXU Energy'
    },
    {
        providerId: '159',
        providerName: 'Reliant'
    },
    {
        providerId: '206',
        providerName: 'Green Mountain'
    },
    {
        providerId: '183',
        providerName: 'Champion Energy'
    }
];

export const API_KEY = '80e7bff79b7be5a59fac7af439572782';
export const API_URL = 'https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta';
export const POWERLEGO_API_URL = 'https://www.powerlego.com/ApiGateway';
export const GOOGLE_API_KEY = 'AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0';