import React, { PureComponent, useEffect, useState } from 'react';
import { ChartTooltips, ModalCloseBtn } from "./styles/common.styled";
import {
    Button, TextField, Modal, Box, Grid
} from "@mui/material"
import type { UsageAdjustProps } from '../types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { UsageAdjustChart, UsageAdjustItem, UsageAdjustRow, UsageAdjustWrapper } from './styles/usage.styled';
import NumberFormat from 'react-number-format';
import { ModalBodyStyle } from './styles/common.styled';

const tooltipStyles = {
    cursor: {
        fill: 'transparent'
    },
}

export const UsageAdjust: React.FC<UsageAdjustProps> = ({
    usage,
    disabled,
    handleChangeUsage,
    handleReset,
    isEstMode,
    handleSwitchToEstMode,
    sourceFrom
}) => {
    const [ values, setValues ] = useState<number[]|undefined>(undefined);
    const [ showAdjustModal, setShowAdjustModal ] = useState(false);
    const [ isSimpleMode, setIsSimpleMode ] = useState(false);
    const currentMonth = moment().month();
    
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    useEffect(() => {
        if (usage) setValues(usage);
    }, [usage])

    if (!values) return null;

    let chartData = [];
    for (let i in values) {
        chartData.push({ name: months[parseInt(i)], value: values[i] })
    }

    const handleCloseModal = () => {
        setShowAdjustModal(false);
    }

    return (
        <UsageAdjustWrapper>
            <h5>
                {`Your ${isEstMode ? 'Estimated' : 'Actual'} Usage`}
                {isEstMode ? <Button size="small" variant="outlined" style={{marginLeft:12}} onClick={() => {
                    setShowAdjustModal(true);
                }}>Adjust</Button> :
                <Button size="small" variant="outlined" style={{marginLeft:12}} onClick={() => {
                    handleSwitchToEstMode();
                }}>Switch to Estimate Mode</Button>
            }</h5>
            {
                isEstMode &&
                <p>Rate is calculated by estimated usage by zip code and house size. Adjust with your own estimates.</p>
            }
            <UsageAdjustChart>
                <ResponsiveContainer width="100%" height={130}>
                    <BarChart
                        data={chartData}
                        margin={{
                            top: 12, right: 24, left: -24, bottom: 0,
                        }}
                    >
                        <XAxis dataKey="name" tick={{fontSize:11}} stroke="#bbb" interval={1} />
                        <YAxis tick={{fontSize:11, fill: '#bbb'}} stroke="#fff" />
                        <CartesianGrid vertical={false} strokeDasharray="2" />
                        <Tooltip
                            { ... tooltipStyles }
                            wrapperStyle={{ zIndex: 1 }}
                            content={ <CustomTooltip /> }
                        />
                        <Bar dataKey="value"
                            isAnimationActive={true}
                            maxBarSize={10}
                            shape={BarWithTopBorder()}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </UsageAdjustChart>
            {
                (sourceFrom && sourceFrom !== 'undefined') && !isEstMode &&
                <div style={{marginTop:-4,fontSize:13,color:'#999',fontStyle:'italic',paddingLeft:2}}>Actual usage data from <span style={{textTransform:'uppercase'}}>{sourceFrom}</span></div>
            }
            <Modal open={showAdjustModal} onClose={handleCloseModal}>
                <Box sx={ModalBodyStyle}>
                    <Grid container justifyContent="flex-end" sx={{mt:1,mb:2}}>
                        <ModalCloseBtn onClick={() => handleCloseModal()} />
                    </Grid>
                    <UsageAdjustRow>
                        {
                            isSimpleMode ?
                            <UsageAdjustItem>
                                <TextField
                                    label="Usage"
                                    value={values[currentMonth]}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        const val = evt.currentTarget.value;
                                        const newValues = new Array(12).fill(parseInt(val));
                                        setValues(newValues);
                                    }}
                                    required
                                    disabled={disabled}
                                />
                            </UsageAdjustItem> :
                            values.map((val, idx) => (
                                <UsageAdjustItem key={idx}>
                                    <TextField
                                        label={months[idx]}
                                        value={val}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                            const val = evt.currentTarget.value;
                                            let [ ...newValues ] = values;
                                            newValues[idx] = val ? parseInt(val) : 0;
                                            setValues(newValues);
                                        }}
                                        required
                                        disabled={disabled}
                                    />
                                </UsageAdjustItem>
                            ))
                        }
                    </UsageAdjustRow>
                    <Grid container justifyContent="center" sx={{mt:3,mb:5}}>
                        <Button variant="text" onClick={() => {setIsSimpleMode(!isSimpleMode)}}>{
                            isSimpleMode ? 'Prefer more accurate predication?' : 'Prefer simple monthly view?'
                        }</Button>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button variant="outlined" onClick={() => {
                            handleReset();
                            handleCloseModal();
                        }} disabled={disabled} style={{marginRight:12}}>Reset</Button>
                        <Button variant="contained" onClick={() => {
                            let usages = values;
                            if (isSimpleMode && !usages.every((val: number) => val === usages[0])) {
                                usages = new Array(12).fill(values[currentMonth]);
                            }
                            handleChangeUsage(usages);
                            handleCloseModal();
                        }} disabled={disabled}>Confirm</Button>
                    </Grid>
                </Box>
            </Modal>
        </UsageAdjustWrapper>
    )
}

class CustomTooltip extends PureComponent {
    render() {
        const { active, payload }: Readonly<any> = this.props;
        
        if (!active || !payload || !payload[0]) return null;
        const { name, value } = payload[0].payload;
        
        return (
            <ChartTooltips>
                <h6>{name}</h6>
                <p><NumberFormat value={value} displayType={'text'} thousandSeparator={true} suffix={' kWh'} /></p>
            </ChartTooltips>
        )
    }
}

const BarWithTopBorder = () => {
	return (props: any) => {
		const { x, y, width, height } = props;

		return (
			<g>
                <defs>
                    <linearGradient id="chart-gradient" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="0%" stop-color="#E5E5E5" />
                        <stop offset="100%" stop-color="#B4B4B4" />
                    </linearGradient>
                </defs>
				<rect x={x} y={y} width={width} height={height} stroke="none" className="bar" rx="2" ry="2" fill="url(#chart-gradient)" />
			</g>
		)
	}
}